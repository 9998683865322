import { LIST_CRYPTO_PRICE_LIST, LIST_PRICE_HISTORY_OF_CRYPTO, RESET_CRYPTO_PRICE_LIST, RESET_PRICE_HISTORY_OF_CRYPTO } from "../types";

const INITIAL_STATE = {
    priceHistory: [],
    priceList: {},
};

const cryptoReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_PRICE_HISTORY_OF_CRYPTO:
            return {
                ...state,
                priceHistory: payload,
            };

        case RESET_PRICE_HISTORY_OF_CRYPTO:
            return INITIAL_STATE;

        case LIST_CRYPTO_PRICE_LIST:
            return {
                ...state,
                priceList: payload,
            };

        case RESET_CRYPTO_PRICE_LIST:
            return INITIAL_STATE;

        default:
            return state;
    }
};

export default cryptoReducer;
