import { combineReducers } from "redux";
import { LOGOUT } from "../types";
import loadingReducer from "./loadingReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import viewModeReducer from "./viewModeReducer";
import modalReducer from "./modalReducer";
import cryptoReducer from "./cryptoReducer";

const appReducer = combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    viewmode: viewModeReducer,
    user: userReducer,
    modal: modalReducer,
    crypto: cryptoReducer,
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
