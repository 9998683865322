import { Grain } from "@material-ui/icons";

// import Login from "views/auth/Login.js";
// import Home from "views/home";
import Companies from "views/companies/companies";
import StockPriceHistory from "views/companies/stockPriceHistory";
import Modules from "views/companies";
import PredictionHistory from "views/history";
import Results from "views/results";
import Listings from "views/auth/home/listings";
import ResultHistory from "views/auth/home/resultHistory";
import CryptoSection from "views/auth/home/crypto";

var adminRoutes = [
    {
        path: "/home",
        name: "home",
        icon: "fa-solid fa-house",
        iconColor: "Primary",
        component: Modules,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/predictions",
        name: "history",
        icon: "fa fa-history",
        iconColor: "Primary",
        component: PredictionHistory,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/results",
        name: "results",
        icon: "fa-solid fa-rectangle-list",
        iconColor: "Primary",
        component: Results,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/companies/:country?/:index",
        name: "Companies",
        icon: "fa fa-home",
        iconColor: "Primary",
        component: Companies,
        layout: "/app",
        visibility: false,
    },
    {
        path: "/price-history",
        name: "Stock historical data",
        icon: "fa fa-home",
        iconColor: "Primary",
        component: StockPriceHistory,
        layout: "/app",
        visibility: false,
    },
    {
        divider: true,
        layout: "/app",
        visibility: true,
    },
];

var authRoutes = [
    // {
    //     path: "/login",
    //     name: "Home",
    //     icon: Grain,
    //     iconColor: "Primary",
    //     component: Home,
    //     layout: "/auth",
    //     visibility: true,
    // },
    {
        path: "/listings",
        name: "Listings",
        icon: Grain,
        iconColor: "Primary",
        component: Listings,
        layout: "/auth",
        visibility: true,
    },
    {
        path: "/history",
        name: "History",
        icon: Grain,
        iconColor: "Primary",
        component: ResultHistory,
        layout: "/auth",
        visibility: true,
    },
    {
        path: "/crypto",
        name: "Crypto",
        icon: Grain,
        iconColor: "Primary",
        component: CryptoSection,
        layout: "/auth",
        visibility: true,
    },
];
export { adminRoutes, authRoutes };
