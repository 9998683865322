export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_VIEWMODE = "TOGGLE_VIEWMODE";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const LIST_STOCK_PRICE_HISTORY = "LIST_STOCK_PRICE_HISTORY";
export const RESET_STOCK_PRICE_HISTORY = "RESET_STOCK_PRICE_HISTORY";

export const LIST_COMPANIES_LIST = "LIST_COMPANIES_LIST";
export const RESET_COMPANIES_LIST = "RESET_COMPANIES_LIST";

export const LIST_LOT_AVAILABILITY_OF_COUNTRIES = "LIST_LOT_AVAILABILITY_OF_COUNTRIES";
export const RESET_LOT_AVAILABILITY_OF_COUNTRIES = "RESET_LOT_AVAILABILITY_OF_COUNTRIES";

export const SHOW_TOTAL_COINS = "SHOW_TOTAL_COINS";
export const RESET_TOTAL_COINS = "RESET_TOTAL_COINS";

export const LIST_PREDICTION_HISTORY = "LIST_PREDICTION_HISTORY";
export const RESET_PREDICTION_HISTORY = "RESET_PREDICTION_HISTORY";

export const LIST_SIGNAL_RESULT_HISTORY = "LIST_SIGNAL_RESULT_HISTORY";
export const RESET_SIGNAL_RESULT_HISTORY = "RESET_SIGNAL_RESULT_HISTORY";

export const LIST_PRICE_HISTORY_OF_CRYPTO = "LIST_PRICE_HISTORY_OF_CRYPTO";
export const RESET_PRICE_HISTORY_OF_CRYPTO = "RESET_PRICE_HISTORY_OF_CRYPTO";

export const LIST_CRYPTO_PRICE_LIST = "LIST_CRYPTO_PRICE_LIST";
export const RESET_CRYPTO_PRICE_LIST = "RESET_CRYPTO_PRICE_LIST";

