import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Grid, MenuItem, Select, Switch } from "@material-ui/core";
import { resetSignalResultHistory, getSignalResultHistory } from "store/actions";
import { countries } from "datas/countries";
import { toTimeZone } from "utils";
import { Title, ViewModeToggler, EmptyBox } from "components/StyledComponents";
import ExpandableListView from "components/ExpandableListView/ExpandableListView";
import CustomTable from "components/CustomTable/CustomTable";
var moment = require("moment-timezone");

function ResultHistory() {
    const dispatch = useDispatch();
    const [tableView, setTableView] = useState(false);
    const [selection, setSelection] = useState("usa_dj");

    const { resultHistoryData, showViewMore } = useSelector(
        ({ user: { resultHistory, showViewMore } }) => ({
            resultHistoryData: resultHistory,
            showViewMore,
        })
    );

    useEffect(() => {
        dispatch(resetSignalResultHistory());
        fetchMoreNfts();
    }, [selection, tableView]);

    const fetchMoreNfts = () => {
        dispatch(getSignalResultHistory({ type: selection }));
    };

    const colorIndicator = (element, arr = [], style = {}) => {
        arr.map((e) => {
            if (element[e.field] === e.value) {
                style = { color: e.color };
            }
            return null;
        });
        return style;
    };

    return (
        <>
            <div>
                <Grid container justify="center" spacing={3}>
                    <Grid item lg={12} xs={10}>
                        <Title>
                            <h4>Signal result history</h4>
                        </Title>
                    </Grid>

                    <Grid item lg={6} xs={6}>
                        <ViewModeToggler>
                            <b>{"Table view"}</b>
                            <Switch onChange={(e) => setTableView(e.target.checked)} />
                        </ViewModeToggler>
                    </Grid>

                    <Grid item lg={6} xs={6}>
                        <FormControl
                            variant="outlined"
                            size="small"
                            style={{
                                minWidth: "150px",
                                float: "right",
                                marginRight: "-10px",
                            }}
                        >
                            <Select
                                value={selection}
                                onChange={(event) => {
                                    setSelection(event.target.value);
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                {countries.map((e, i) => (
                                    <MenuItem key={i} value={e.code}>
                                        {e.country}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {resultHistoryData?.length ? (
                        <>
                            {tableView ? (
                                <CustomTable
                                    columns={[
                                        {
                                            title: "Predictions for",
                                            render: (rowData) => <>{moment(rowData?.createdAt).format("DD/MM/YYYY")}</>,
                                        },
                                        {
                                            title: "Company name",
                                            render: (rowData) => (
                                                <>
                                                    <div>
                                                        {rowData?.company_name} {`(${rowData?.scrip_name})`}
                                                    </div>
                                                </>
                                            ),
                                        },
                                        { title: "Country", field: "country" },
                                        { title: "Cmp", field: "cmp" },
                                        { title: "Target", field: "target" },
                                        {
                                            title: "signal",
                                            render: (rowData) => (
                                                <b
                                                    style={{
                                                        color:
                                                            rowData.signal === "BUY"
                                                                ? "#25cc00"
                                                                : rowData.signal === "SELL"
                                                                    ? "red"
                                                                    : "#fff",
                                                    }}
                                                >
                                                    {rowData?.signal}
                                                </b>
                                            ),
                                        },
                                        {
                                            title: "updated on",
                                            render: (rowData) => (
                                                <small>
                                                    {moment(rowData?.createdAt).format("DD/MM/YYYY h:mm A z(Z)")} <br />
                                                    {toTimeZone(rowData?.createdAt, "America/New_York")}
                                                </small>
                                            ),
                                        },
                                        {
                                            title: "Results published on",
                                            render: (rowData) => (
                                                <>
                                                    {rowData.createdAt === rowData.updatedAt ? (
                                                        "pending"
                                                    ) : (
                                                        <small>
                                                            {moment(rowData?.updatedAt).format("DD/MM/YYYY h:mm A z(Z)")} <br />
                                                            {toTimeZone(rowData?.updatedAt, "America/New_York")}
                                                        </small>
                                                    )}
                                                </>
                                            ),
                                        },
                                        { title: "Day's high", field: "days_high" },
                                        { title: "Day's low", field: "days_low" },
                                        { title: "Status", field: "status" },
                                    ]}
                                    data={resultHistoryData}
                                    showViewMoreButton={showViewMore}
                                    viewMoreButtonClick={() => fetchMoreNfts()}
                                // onRowClick={() => setCloseModal(true)}
                                />
                            ) : (
                                <>
                                    <ExpandableListView
                                        pairs={[
                                            {
                                                key: "Updated on",
                                                render: (rowData) => (
                                                    <small>
                                                        {moment(rowData?.createdAt).format("DD/MM/YYYY h:mm A z(Z)")}
                                                    </small>
                                                ),
                                            },
                                            {
                                                key: "Predictions for",
                                                render: (rowData) => (
                                                    <small>{moment(rowData?.createdAt).format("DD/MM/YYYY")}</small>
                                                ),
                                            },
                                            { key: "Country", value: "country" },
                                            { key: "Current market price", value: "cmp" },
                                            {
                                                key: "Target price",
                                                value: "target",
                                                colorIndication: { value: "signal", condition: "BUY" },
                                            },
                                            {
                                                key: "Signal",
                                                value: "signal",
                                                colorIndication: { value: "signal", condition: "BUY" },
                                            },
                                            {
                                                key: "Result published on",
                                                render: (rowData) => (
                                                    <>
                                                        {rowData.createdAt === rowData.updatedAt ? (
                                                            "pending"
                                                        ) : (
                                                            <small>
                                                                {moment(rowData?.updatedAt).format("DD/MM/YYYY h:mm A z(Z)")}
                                                            </small>
                                                        )}
                                                    </>
                                                ),
                                            },
                                            { key: "Day's high", value: "days_high" },
                                            { key: "Day's low", value: "days_low" },
                                            {
                                                key: "Status",
                                                render: (rowData) => (
                                                    <div
                                                        style={colorIndicator(rowData, [
                                                            { field: "status", value: "success", color: "#6bc654" },
                                                            { field: "status", value: "failure", color: "red" },
                                                            { field: "status", value: "pending", color: "#a1a1a1" },
                                                        ])}
                                                    >
                                                        {rowData.status}
                                                    </div>
                                                ),
                                            },
                                        ]}
                                        leftTitle={[
                                            {
                                                key: "company_name", 
                                                render: (e) => (
                                                    <>{e.company_name} {`(${e.scrip_name})`}</>
                                                ) 
                                            },
                                            {
                                                key: "createdAt", 
                                                render: (e) => (
                                                    <>
                                                        {moment(e.createdAt).format(
                                                            "DD/MM/YYYY h:mm A z(Z)"
                                                        )}
                                                    </>
                                                )
                                            }
                                        ]}
                                        rightTitle={{ 
                                            main: {render: (e) => (
                                                <>
                                                    <b>{e.cmp}</b>
                                                    <i className="fas fa-long-arrow-alt-right" />
                                                    <b style={colorIndicator(e, [
                                                        {field: "signal", value: "BUY", color: "#6bc654"},
                                                        {field: "signal", value: "SELL", color: "red"},
                                                    ])}>
                                                        {e.target}
                                                    </b>
                                                </>
                                            )},
                                            sub: {render: (e) => (
                                            <div
                                                style={colorIndicator(e, [
                                                    { field: "status", value: "success", color: "#6bc654" },
                                                    { field: "status", value: "failure", color: "red" },
                                                    { field: "status", value: "pending", color: "#a1a1a1" },
                                                ])}
                                            >
                                                {e.status}
                                            </div>)} 
                                        }}
                                        data={resultHistoryData}
                                        showViewMoreButton={showViewMore}
                                        viewMoreButtonClick={() => fetchMoreNfts()}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <EmptyBox>No data found</EmptyBox>
                    )}
                </Grid>
            </div>
        </>
    );
}

export default ResultHistory;
