import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Input } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { resetStockPriceHistory, listStockPriceHistory } from "store/actions";
import { history, dateFromXDaysAgo, getCurrentDate, currencyFormat } from "utils";
import { Title, EmptyBox } from "components/StyledComponents";
import CustomTable from "components/CustomTable/CustomTable";
import styled from "styled-components";
import moment from "moment";

const Tag = styled.div`
    width: 10px;
`;

function StockPriceHistory() {
    const dispatch = useDispatch();
    const { data } = useLocation();
    const [from, setFrom] = useState(dateFromXDaysAgo(7));
    const [to, setTo] = useState(getCurrentDate());

    const { stockPriceHistoryData } = useSelector(({ user: { stockPriceHistory } }) => ({
        stockPriceHistoryData: stockPriceHistory,
    }));

    useEffect(() => {
        if (!data) {
            history.goBack();
        } else {
            dispatch(resetStockPriceHistory());
            dispatch(
                listStockPriceHistory({ startDate: from, endDate: to, symbol: data?.scrip_name })
            );
        }
    }, [from, to]);

    return (
        <>
            <div>
                <>
                    <Grid container justify="center" spacing={3}>
                        <Grid item lg={12} xs={10}>
                            <Title>
                                <h2>{data?.company_name}</h2>
                                <h5>historical data</h5>
                            </Title>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Tag>from</Tag>
                                    <Input type="date" onChange={(e) => setFrom(e.target.value)} />
                                </Grid>
                                <Grid item>
                                    <Tag>to</Tag>
                                    <Input type="date" onChange={(e) => setTo(e.target.value)} />
                                </Grid>
                            </Grid>
                        </Grid>

                        {stockPriceHistoryData?.length > 0 ? (
                            <>
                                <CustomTable
                                    // title={stockPriceHistoryData[0]?.date}
                                    columns={[
                                        {
                                            title: "Date",
                                            render: (rowData) => (
                                                <>
                                                    {moment
                                                        .unix(rowData?.date)
                                                        .format("DD/MM/YYYY")}
                                                </>
                                            ),
                                        },
                                        {
                                            title: "Open",
                                            render: (rowData) => (
                                                <>{currencyFormat(rowData?.open, "$")}</>
                                            ),
                                        },
                                        {
                                            title: "High",
                                            render: (rowData) => (
                                                <>{currencyFormat(rowData?.high, "$")}</>
                                            ),
                                        },
                                        {
                                            title: "Low",
                                            render: (rowData) => (
                                                <>{currencyFormat(rowData?.low, "$")}</>
                                            ),
                                        },
                                        {
                                            title: "Close",
                                            render: (rowData) => (
                                                <>{currencyFormat(rowData?.close, "$")}</>
                                            ),
                                        },
                                        {
                                            title: "Adj close",
                                            render: (rowData) => (
                                                <>{currencyFormat(rowData?.adjclose, "$")}</>
                                            ),
                                        },
                                    ]}
                                    data={stockPriceHistoryData}
                                />
                            </>
                        ) : (
                            <EmptyBox> No data found </EmptyBox>
                        )}
                    </Grid>
                </>
            </div>
        </>
    );
}

export default StockPriceHistory;
