import React, { useState } from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import LoginWithOtp from "./LoginWithOtp";
import SendOtp from "./SendOtp";


function Login() {
    const [email,setEmail] = useState();

    return (
        <>
            <Grid item>
                <Card  style={{borderRadius:"20px", backgroundColor:"#101427",boxShadow:"0 3px 70px #000000"}}>
                    <div style={{textAlign:"center",marginTop:"20px",fontSize:"18px"}}>
                        <p>Sign In/Sign Up</p>
                    </div>
                    <CardContent style={{padding:"30px 50px 20px"}}>
                        {!email?
                        <SendOtp setEmail={ setEmail }/> : <LoginWithOtp email={ email }/>
                        }
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default Login;
