import { 
    LIST_STOCK_PRICE_HISTORY, 
    RESET_STOCK_PRICE_HISTORY, 
    LIST_COMPANIES_LIST, 
    RESET_COMPANIES_LIST, 
    RESET_TOTAL_COINS, 
    SHOW_TOTAL_COINS, 
    LIST_PREDICTION_HISTORY, 
    RESET_PREDICTION_HISTORY, 
    LIST_SIGNAL_RESULT_HISTORY, 
    RESET_SIGNAL_RESULT_HISTORY, 
    LIST_LOT_AVAILABILITY_OF_COUNTRIES,
    RESET_LOT_AVAILABILITY_OF_COUNTRIES
} from "../types";

const INITIAL_STATE = {
    stockPriceHistory: [],
    countriesList: [],
    companiesList: [],
    predictionHistory: [],
    resultHistory: [],
    totalCoins: {},
    showViewMore: false,
    pageNumber: 1,
    pageSize: 10,
};

const userReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_STOCK_PRICE_HISTORY:
            return {
                ...state,
                stockPriceHistory: payload,
            };

        case LIST_COMPANIES_LIST:
            return {
                ...state,
                companiesList: payload,
            };

        case LIST_LOT_AVAILABILITY_OF_COUNTRIES:
            return {
                ...state,
                countriesList: payload,
            };

        case SHOW_TOTAL_COINS:
            return {
                ...state,
                totalCoins: payload,
            };

        case LIST_PREDICTION_HISTORY:
            return {
                ...state,
                pageSize: 10,
                ...payload,
            };

        case LIST_SIGNAL_RESULT_HISTORY:
            return {
                ...state,
                pageSize: 10,
                ...payload,
            };

        case RESET_SIGNAL_RESULT_HISTORY:
            return INITIAL_STATE;

        case RESET_PREDICTION_HISTORY:
            return INITIAL_STATE;

        case RESET_STOCK_PRICE_HISTORY:
            return {
                ...state,
                stockPriceHistory: INITIAL_STATE.stockPriceHistory,
            };

        case RESET_COMPANIES_LIST:
            return {
                ...state,
                companiesList: INITIAL_STATE.companiesList,
            };

        case RESET_LOT_AVAILABILITY_OF_COUNTRIES:
            return {
                ...state,
                countriesList: INITIAL_STATE.countriesList,
            };

        case RESET_TOTAL_COINS:
            return {
                ...state,
                totalCoins: INITIAL_STATE.totalCoins,
            };

        default:
            return state;
    }
};

export default userReducer;
