import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Modal, Switch } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { history } from "utils";
import { countries } from "datas/countries";
import { 
    Title, 
    EmptyBox, 
    ContainedButton, 
    OutlinedButton,
    ViewModeToggler 
} from "components/StyledComponents";
import { 
    resetCompaniesList, 
    listAllCompanies, 
    resetTotalCoins, 
    getTotalCoins, 
    updateLotsAndCoins 
} from "store/actions";
import styled from 'styled-components';
import CustomTable from "components/CustomTable/CustomTable";
import CustomTextButton from "components/CustomButton/CustomTextButton";
var moment = require('moment-timezone');

const ModalBox = styled.div`
  width: 380px;
  position : absolute;
  top : 50%;
  left : 50%;
  transform: translate(-50%, -50%);
  background-color: #101427;
  color: #cccccc;
  border-radius: 20px;
  border: 1px solid;
  padding: 30px;
  text-align: center;
  box-shadow: 0 3px 70px #000000;
  h3 {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 500px) {
      width : 300px;
  }
`;

const Input = styled.input`
  padding: 15px;
  width: 80%;
  margin-bottom: 20px;
  background-color: #262734;
  color: #fff;
  border: 0;
`;

const Card = styled.div`
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: rgb(16, 20, 39, 0.8);
    #signal {
        float: right;
    }
    i {
        font-size: small;
        float: right;
        background-color: rgb(0, 0, 0, 0.5);
    }
    hr {
        border: 0;
        border-top: 1px solid #919191;
        margin: 0.7em 0;
    }
    #value {
        width: fit-content;
        text-align: center;
    }
    #low {
        float: right;
    }
`;

function Companies() {
    const dispatch = useDispatch();
    const { country, index } = useParams()
    const [modal, setModal] = useState(false);
    const [lotAmount, setLotAmount] = useState(0);
    const [selectedData, setSelectedData] = useState('');
    const [viewMode, setViewMode] = useState(false);
    const [warning, setWarning] = useState(false);
    const { companiesListData } = useSelector(({ user: { companiesList } }) => ({
        companiesListData: companiesList
    }))

    useEffect(() => {
        dispatch(resetCompaniesList());
        dispatch(listAllCompanies({ countryExchange: country }));
    }, []);

    const takeLots = (data) => {
        if (data.lots_available < 1) {
            setWarning(true);
        }
        data.country = countries[index]?.country
        data.country_code = countries[index]?.code
        data.market = countries[index]?.market
        delete data.createdAt
        delete data.updatedAt
        setSelectedData(data)
        setModal(true)
    }

    return (
        <>
            <div>
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Title>
                            <h3>{countries[index]?.country}, {countries[index]?.market}</h3>
                            <h5>{companiesListData[0]?.date}</h5>
                        </Title>
                    </Grid>

                    {companiesListData?.length > 0 ? (
                        <>
                            <Grid item lg={6} xs={6}>
                                <ViewModeToggler>
                                    <b>{"Table view"}</b>
                                    <Switch onChange={(e) => setViewMode(e.target.checked)} />
                                </ViewModeToggler>
                            </Grid>
                            {countries[index]?.endTime ?
                            <Grid item lg={6} xs={6}>
                                <div style={{textAlign:"right", marginRight: "-10px"}}>
                                    <small><b>Game ends at: {" "}
                                    {moment(countries[index]?.endTime).tz("Asia/Kolkata").format("h:mm A z(Z)")}, <br/>
                                    {moment(countries[index]?.endTime).tz(countries[index]?.timeZone).format("h:mm A z(Z)")}
                                    </b></small>
                                </div>
                            </Grid>: null}

                            {viewMode ?
                                <CustomTable
                                    columns={[
                                        {
                                            title: "Company name",
                                            render: (rowData) => (
                                                <>
                                                    {rowData?.company_name}{" "}
                                                    {`(${rowData?.scrip_name})`}
                                                </>
                                            ),
                                        },
                                        { title: "Cmp", field: "cmp" },
                                        { title: "Target", field: "target" },
                                        {
                                            title: "signal",
                                            render: (rowData) => (
                                                <b
                                                    style={{
                                                        color:
                                                            rowData.signal === "BUY"
                                                                ? "#25cc00"
                                                                : rowData.signal ===
                                                                    "SELL"
                                                                    ? "red"
                                                                    : "#fff",
                                                    }}
                                                >
                                                    {rowData?.signal}
                                                </b>
                                            ),
                                        },
                                        {
                                            title: "lots available",
                                            // field: "lots_available" 
                                            render: (rowData) => (
                                                <>
                                                    {rowData?.lots_available < 1 ?
                                                        <b style={{ color: "red" }}>no more lots</b> :
                                                        <b>{rowData?.lots_available}</b>}
                                                </>
                                            )
                                        },
                                        {
                                            title: "actions",
                                            render: (rowData) => (
                                                <>
                                                    <CustomTextButton
                                                        title="play against"
                                                        size="small"
                                                        style={{ backgroundColor: "#616A94" }}
                                                        onClick={() => takeLots(rowData)}
                                                    />
                                                    &nbsp;
                                                    <CustomTextButton
                                                        title="get historical data"
                                                        size="small"
                                                        style={{ backgroundColor: "#101427", border: "1px solid #616A94" }}
                                                        onClick={() =>
                                                            history.push({
                                                                pathname: "/app/price-history",
                                                                data: rowData,
                                                            })}
                                                    />
                                                </>
                                            ),
                                        },
                                    ]}
                                    data={companiesListData}
                                />
                                :
                                <div style={{ width: "100%" }}>
                                    <Grid container>
                                        {companiesListData.map((e, i) => (
                                            <Grid item lg={4} md={6} xs={12} key={i}>
                                                <Card>
                                                    <b>{e.company_name} </b>
                                                    <b id="signal" style={{
                                                        color: e.signal === "BUY"
                                                            ? "#25cc00"
                                                            : e.signal ===
                                                                "SELL"
                                                                ? "red"
                                                                : "#fff",
                                                    }}>{e.signal}</b><br />
                                                    {/* <i>{e.date}</i> */}
                                                    <hr />
                                                    <Grid container justify="center">
                                                        <Grid item>
                                                            <div id="value"><b>{"cmp"}</b><br />{e.cmp}</div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div id="value"><b>{"target"}</b><br />{e.target}</div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div id="value"><b>{"lots available"}</b><br />
                                                                {e?.lots_available < 1 ?
                                                                    <b style={{ color: "red" }}>no more lots</b> :
                                                                    <>{e?.lots_available}</>}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <hr />
                                                    <b><CustomTextButton
                                                        title="play against"
                                                        size="small"
                                                        style={{ backgroundColor: "#616A94" }}
                                                        onClick={() => takeLots(e)} />
                                                    </b>
                                                    <div id="low">
                                                        <b><CustomTextButton
                                                            title="get historical data"
                                                            size="small"
                                                            style={{ backgroundColor: "#101427", border: "1px solid #616A94" }}
                                                            onClick={() =>
                                                                history.push({
                                                                    pathname: "/app/price-history",
                                                                    data: e,
                                                                })}
                                                        /></b>
                                                    </div>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            }
                        </>
                    ) : (
                        <EmptyBox> No data found </EmptyBox>
                    )}
                    <Modal
                        open={modal}
                        onClose={() => {
                            setWarning(false)
                            setModal(false)
                        }}
                    >
                        <ModalBox>
                            {warning ?
                                <>
                                <b style={{color: "red"}}>There is no more lots available</b>
                                <p>
                                    You can't play right now.
                                    wait for the signals get updated.
                                </p>
                                </> :
                                <>
                                    <b>1 lot = 1 coin</b>
                                    <p>
                                        You are going to stand against the system's prediction.
                                        If your prediction wins, you will get extra coins.
                                        If the system wins, you will lose your coins.
                                    </p>
                                    <p>
                                        Example:- If you played with <b>10</b> coins and you win,
                                        you will get <b>10 x 1.7</b> coins. that is <b>17</b> coins.
                                        If you fail you will lose <b>10</b> coins.
                                    </p>
                                    <h4>Enter the amount of lots you are taking</h4>
                                    <Input
                                        type="number"
                                        onChange={(e) => setLotAmount(e.target.value)}
                                    />
                                    <br />
                                    <OutlinedButton onClick={() => setModal(false)}>
                                        Cancel
                                    </OutlinedButton>
                                    &nbsp;&nbsp;&nbsp;
                                    <ContainedButton
                                        onClick={async () => {
                                            if (lotAmount <= 1) {
                                                toast.error("Amount must be greater than 1");
                                            } else {
                                                setModal(false)
                                                await dispatch(updateLotsAndCoins({ lotAmount: lotAmount, data: selectedData }));
                                                await dispatch(resetCompaniesList());
                                                await dispatch(listAllCompanies({ countryExchange: country }));
                                                await dispatch(resetTotalCoins());
                                                await dispatch(getTotalCoins());
                                            }
                                        }}>
                                        Submit
                                    </ContainedButton>
                                </>}
                        </ModalBox>
                    </Modal>
                </Grid>
            </div >
        </>
    );
}

export default Companies;