import React, { useEffect } from "react";
import styled from 'styled-components';
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { history } from "utils";
import { EmptyBox, Title } from "components/StyledComponents";
import {
    resetTotalCoins,
    getTotalCoins,
    resetCountriesAvailabilityList,
    listAvailabilityOfCountries
} from "store/actions";

const Box = styled.div`
    min-width: 150px;
    display: flex;
    align-items: center;
    background-color: rgb(16, 20, 39, 0.8);
    border-radius: 15px;
    cursor: pointer;
    i {
        font-size: 10px;
        padding: 0px 10px;
    }
    &:hover {
        color: #000;
        background-color: #F9CA26;
    }
`;

function Modules() {
    const dispatch = useDispatch();

    const { countriesListData } = useSelector(({ user: { countriesList } }) => ({
        countriesListData: countriesList
    }))

    useEffect(() => {
        fetchContriesList();
    }, []);

    const fetchContriesList = async() => {
        await dispatch(resetTotalCoins());
        await dispatch(getTotalCoins());
        await dispatch(resetCountriesAvailabilityList());
        await dispatch(listAvailabilityOfCountries());
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item lg={12} xs={12}>
                    <Title>
                        <i className="fa fa-circle" style={{ color: "#25cc00" }} />
                        available
                        <i className="fa fa-circle" style={{ color: "red" }} />
                        unavailable
                    </Title>
                </Grid>
                {countriesListData ?
                    <>
                        {countriesListData.map((e, i) => (
                            <Grid item lg={3} xs={6} key={i}>
                                <Box
                                    onClick={() => {
                                        history.push(`/app/companies/${e.code}/${i}`);
                                    }}>
                                    <i className="fa fa-circle"
                                        style={{ color: e.availability ? "#25cc00" : "red" }} />
                                    <h4 style={{ color: e.availability ? "#ffffff" : "grey" }} >
                                        {e.country}
                                    </h4>
                                </Box>
                            </Grid>
                        ))}
                    </> :
                    <EmptyBox>No data found</EmptyBox>}
            </Grid>
        </div>
    );
}

export default Modules;