import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Grid, MenuItem, Modal, Select, Switch } from "@material-ui/core";
import { resetCompaniesList, listAllCompanies, toggleModal } from "store/actions";
import { countries } from "datas/countries";
import { toTimeZone } from "utils";
import { Title, EmptyBox, ViewModeToggler } from "components/StyledComponents";
import { ContainedButton, OutlinedButton } from "components/StyledComponents/Button";
import ExpandableListView from "components/ExpandableListView/ExpandableListView";
import CustomTable from "components/CustomTable/CustomTable";
import CustomTextButton from "components/CustomButton/CustomTextButton";
import styled from "styled-components";
import moment from "moment";

const ModalBox = styled.div`
    width: 380px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #101427;
    color: #cccccc;
    border-radius: 20px;
    padding: 30px 20px;
    text-align: center;
    box-shadow: 0 3px 70px #000000;
    h3 {
        margin-bottom: 30px;
    }

    @media screen and (max-width: 500px) {
        width: 300px;
    }
`;

function Listings() {
    const dispatch = useDispatch();
    const [tableView, setTableView] = useState(false);
    const [closeModal, setCloseModal] = useState(false);
    const [selection, setSelection] = useState("usa_dj");

    const { companiesListData } = useSelector(({ user: { companiesList } }) => ({
        companiesListData: companiesList,
    }));

    useEffect(() => {
        dispatch(resetCompaniesList());
        dispatch(listAllCompanies({ countryExchange: selection }));
    }, [selection]);

    const colorIndicator = (element, arr = [], style = {}) => {
        arr.map((e) => {
            if (element[e.field] === e.value) {
                style = { color: e.color };
            }
            return null;
        });
        return style;
    };

    return (
        <>
            <Grid container justify="center" spacing={3}>
                <Grid item lg={12} xs={10}>
                    <Title>
                        <h4>{companiesListData[0]?.date}</h4>
                    </Title>
                </Grid>

                <Grid item lg={6} xs={6}>
                    <ViewModeToggler>
                        <b>{"Table view"}</b>
                        <Switch onChange={(e) => setTableView(e.target.checked)} />
                    </ViewModeToggler>
                </Grid>

                <Grid item lg={6} xs={6}>
                    <FormControl
                        variant="outlined"
                        size="small"
                        style={{
                            minWidth: "150px",
                            float: "right",
                            marginRight: "-10px",
                        }}
                    >
                        <Select
                            value={selection}
                            onChange={(event) => {
                                setSelection(event.target.value);
                            }}
                        >
                            {countries.map((e, i) => (
                                <MenuItem key={i} value={e.code}>
                                    {e.country}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {companiesListData?.length ? (
                    <>
                        {tableView ? (
                            <CustomTable
                                columns={[
                                    {
                                        title: "Updated on",
                                        render: (rowData) => (
                                            <small>
                                                {moment(rowData?.createdAt).format("DD/MM/YYYY h:mm A z(Z)")} <br />
                                                {toTimeZone(rowData?.createdAt, 'America/New_York')}
                                            </small>
                                        )
                                    },
                                    {
                                        title: "Company name",
                                        render: (rowData) => (
                                            <>
                                                {rowData?.company_name}{" "}
                                                {`(${rowData?.scrip_name})`}
                                            </>
                                        ),
                                    },
                                    { title: "Cmp", field: "cmp" },
                                    { title: "Target", field: "target" },
                                    {
                                        title: "signal",
                                        render: (rowData) => (
                                            <b
                                                style={{
                                                    color:
                                                        rowData.signal === "BUY" ? "#25cc00" :
                                                        rowData.signal === "SELL" ? "red" : "#fff",
                                                }}
                                            >
                                                {rowData?.signal}
                                            </b>
                                        ),
                                    },
                                    { title: "lots available", field: "lots_available" },
                                    {
                                        title: "action",
                                        render: () => (
                                            <CustomTextButton
                                                title="play against"
                                                size="small"
                                                style={{ backgroundColor: "#616A94" }}
                                            />
                                        ),
                                    },
                                ]}
                                data={companiesListData}
                                onRowClick={() => setCloseModal(true)}
                            />
                        ) : (
                            <>
                                <ExpandableListView
                                    pairs={[
                                        { key: "Current market price", value: "cmp" },
                                        {
                                            key: "Target price",
                                            value: "target",
                                            colorIndication: { value: "signal", condition: "BUY" }
                                        },
                                        { key: "Signal", value: "signal" },
                                        { key: "Available lots", value: "lots_available" },
                                    ]}
                                    note={(e) => (
                                    <p style={{ 
                                        margin: "5px 0", 
                                        fontSize: "15px", 
                                        backgroundColor: "rgb(0, 0, 0, 0.3)",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        }}>
                                        {/* It is predicted that market price of <b> {e.company_name} </b>
                                        will reach at <b> {e.target} </b> within next market opening. 
                                        if you think this prediction is false, you can play against the system */}
                                        Will market price of <b> {e.company_name} </b>  go {e.signal === "BUY" ? "up" : "down"} to <b> {e.target} </b>
                                        within next market opening?
                                        <br/>
                                        if you think this prediction is false, you can play against the system
                                    </p>
                                    )}
                                    leftTitle={[
                                        {
                                            key: "company_name",
                                            render: (e) => (
                                                <>{e.company_name} {`(${e.scrip_name})`}</>
                                            )
                                        },
                                    ]}
                                    rightTitle={{
                                        main: {render: (e) => (
                                            <>
                                                <b>{e.cmp}</b>
                                                <i className="fas fa-long-arrow-alt-right" />
                                                <b style={colorIndicator(e, [
                                                    {field: "signal", value: "BUY", color: "#6bc654"},
                                                    {field: "signal", value: "SELL", color: "red"},
                                                ])}>
                                                    {e.target}
                                                </b>
                                            </>
                                        )},
                                        sub: { render: (e) => (
                                            <>
                                            {e.lots_available < 1 ? (
                                              <div style={{ color: "red" }}>no more lots</div>
                                            ) : (
                                              <>{e.lots_available} lots available</>
                                            )}
                                          </>
                                        )} 
                                    }}
                                    endButton={{title: "Play against", action: () => setCloseModal(true)}}
                                    data={companiesListData}
                                />
                            </>
                        )}
                    </>
                ) : (
                    <EmptyBox> No data found </EmptyBox>
                )}
            </Grid>

            <Modal open={closeModal} onClose={() => setCloseModal(false)}>
                <ModalBox>
                    <h3>Please sign in first!</h3>
                    <OutlinedButton onClick={() => setCloseModal(false)}>
                        Cancel
                    </OutlinedButton>
                    &nbsp;&nbsp;&nbsp;
                    <ContainedButton
                        onClick={() => {
                            setCloseModal(false);
                            dispatch(toggleModal(true));
                        }}
                    >
                        Ok
                    </ContainedButton>
                </ModalBox>
            </Modal>
        </>
    );
}

export default Listings;
