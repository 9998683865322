import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FilledInput,
  InputAdornment,
  CircularProgress as Loader,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { Email } from "@material-ui/icons";

import { sendOtp } from "store/actions";

function SendOtp({ setEmail }) {
  const dispatch = useDispatch();

  const { isLoading } = useSelector(({ loading }) => ({
    isLoading: loading,
  }));

  return (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
        })}
        onSubmit={async(value) => {
          await dispatch(sendOtp(value));
          setEmail(value);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <FilledInput
              autoComplete="on"
              type="email"
              fullWidth
              placeholder="Enter your email"
              name="email"
              defaultValue={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              }
              required={true}
              error={Boolean(touched.email && errors.email)}
            />
            {Boolean(touched.email && errors.email) ? (
              <div
                style={{
                  display: "block",
                  marginLeft: "10px",
                  color: "red",
                  fontSize: 13,
                }}
              >
                {errors.email}
              </div>
            ) : (
              ""
            )}
            <br />
            <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
              <Button
                id="signin-btn"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <Loader color="secondary" size={20} />
                ) : (
                  "Send Otp"
                )}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

export default SendOtp;
