export const FETCH_TODAYS_PRICE_HISTORY_OF_CRYPTO = `query ($limit: Int, $fromSymbol: String, $toSymbol: String) {
    list_crypto_price_history(limit: $limit, from_symbol: $fromSymbol, to_symbol: $toSymbol){
      message
      data {
        time
        high
        low
        open
        volumefrom
        volumeto
        close
        conversionType
        conversionSymbol
      } 
    }
  }`;

  export const FETCH_CRYPTO_PRICE_LIST = `query{
    get_last_crypto_price_list{
      message
      data {
        prices {
          base
          crypto_symbol
          crypto_price
        } 
        createdAt
      }
    }
  }`;