import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Button,
    FilledInput,
    InputAdornment,
    CircularProgress as Loader,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { Lock } from "@material-ui/icons";

import { loginWithOtp } from "store/actions";
import { toggleModal } from "store/actions";

function LoginWithOtp({ email }) {
    const dispatch = useDispatch();

    const { isLoading } = useSelector(({ loading }) => ({
        isLoading: loading,
    }));

    return (
        <>
            <Formik
                initialValues={{
                    otp: "",
                }}
                validationSchema={Yup.object().shape({
                    otp: Yup.string().max(255).required("otp is required"),
                })}
                onSubmit={(value) => {
                    value.email = email.email;
                    dispatch(loginWithOtp(value));
                    dispatch(toggleModal(false));
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <FilledInput
                            autoComplete="off"
                            type="text"
                            fullWidth
                            placeholder="Enter otp"
                            name="otp"
                            defaultValue={values.otp}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Lock />
                                </InputAdornment>
                            }
                            required={true}
                            error={Boolean(touched.otp && errors.otp)}
                        />
                        {Boolean(touched.otp && errors.otp) ? (
                            <div
                                style={{
                                    display: "block",
                                    marginLeft: "10px",
                                    color: "red",
                                    fontSize: 13,
                                }}
                            >
                                {errors.otp}
                            </div>
                        ) : (
                            ""
                        )}
                        <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                            <Button
                                id="signin-btn"
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                {isLoading ? <Loader color="secondary" size={20} /> : "Sign In"}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
}

export default LoginWithOtp;
