import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { resetPredictionHistory, getPredictionHistory, getTotalCoins } from 'store/actions';
import { FormControl, Grid, MenuItem, Select, Switch } from '@material-ui/core';
import { Title, ViewModeToggler, EmptyBox } from 'components/StyledComponents';
import { countries } from 'datas/countries';
import styled from 'styled-components';
import CustomTextButton from 'components/CustomButton/CustomTextButton';
import CustomTable from 'components/CustomTable/CustomTable';

const Card = styled.div`
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: rgb(16, 20, 39, 0.8);

    #titles {
        width: 70%;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    #country {
        font-size: small;
        color: #8c8a8a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        float: left;
    }
    #signal {
        float: right;
    }
    i {
        font-size: small;
        float: right;
        color: #8c8a8a;
    }
    hr {
        border: 0;
        border-top: 1px solid #919191;
        margin: 0.7em 0;
    }
    #value {
        width: fit-content;
        text-align: center;
    }
    #low {
        float: right;
    }
`;

function Results() {
    const dispatch = useDispatch();
    const [tableView, setTableView] = useState(false);
    const [selection, setSelection] = useState("all");

    const { predictionHistoryData, showViewMore } = useSelector(({ user: { predictionHistory, showViewMore } }) => ({
        predictionHistoryData: predictionHistory,
        showViewMore
    }))

    useEffect(() => {
        dispatch(resetPredictionHistory());
        dispatch(getTotalCoins());
        fetchMoreNfts()
    }, [selection]);

    const fetchMoreNfts = () => {
        dispatch(getPredictionHistory({ type: selection }));
    };

    return (
        <>
            <div>
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Title>
                            <h4>Prediction results</h4>
                        </Title>
                    </Grid>

                    <Grid item lg={6} xs={6}>
                        <ViewModeToggler>
                            <b>{"Table view"}</b>
                            <Switch onChange={(e) => setTableView(e.target.checked)} />
                        </ViewModeToggler>
                    </Grid>

                    <Grid item lg={6} xs={6}>
                        <FormControl
                            variant="outlined"
                            size="small"
                            style={{
                                minWidth: "150px",
                                float: "right",
                                marginRight: "-10px"
                            }}
                        >
                            <Select
                                value={selection}
                                onChange={(event) => {
                                    setSelection(event.target.value);
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                {countries.map((e,i) => (
                                    <MenuItem key={i} value={e.code}>{e.country}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {predictionHistoryData?.length > 0 ? (
                        <>
                            {tableView ?
                                <CustomTable
                                    columns={[
                                        { title: "date", field: "date" },
                                        { title: "country", field: "country" },
                                        { title: "company name", field: "company_name" },
                                        { title: "Cmp", field: "cmp" },
                                        { title: "Target", field: "target" },
                                        {
                                            title: "signal",
                                            render: (rowData) => (
                                                <b
                                                    style={{
                                                        color:
                                                            rowData.signal === "BUY"
                                                                ? "#25cc00"
                                                                : rowData.signal ===
                                                                    "SELL"
                                                                    ? "red"
                                                                    : "#fff",
                                                    }}
                                                >
                                                    {rowData?.signal}
                                                </b>
                                            ),
                                        },
                                        { title: "lot amount", field: "lot_amount" },
                                        {
                                            title: "status",
                                            render: (rowData) => (
                                                <b style={{
                                                    color:
                                                        rowData?.status === "failure" ? "red" :
                                                            rowData?.status === "success" ? "#25cc00" : "#fff"
                                                }}>
                                                    {rowData?.status}
                                                </b>
                                            )
                                        },
                                        { title: "gain", field: "gain" },
                                    ]}
                                    data={predictionHistoryData}
                                /> :
                                <div style={{ width: "100%" }}>
                                    <Grid container>
                                        {predictionHistoryData.map((e, i) => (
                                            <Grid item lg={4} md={6} xs={12} key={i}>
                                                <Card>
                                                    <b>{e.company_name} </b>
                                                    <b id="signal" style={{
                                                        color: e.signal === "BUY"
                                                            ? "#25cc00"
                                                            : e.signal ===
                                                                "SELL"
                                                                ? "red"
                                                                : "#fff",
                                                    }}>{e.signal}</b><br />
                                                    {e?.country ? <div id="country">{`${e?.country},${e?.market}`}</div> : null}
                                                    <i>{e.date}</i>
                                                    <hr />
                                                    <Grid container justify='center'>
                                                        <Grid item>
                                                            <div id="value"><b>{"cmp"}</b><br />{e.cmp}</div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div id="value"><b>{"target"}</b><br />{e.target}</div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div id="value"><b>{"gain"}</b><br />{e.gain}</div>
                                                        </Grid>
                                                    </Grid>
                                                    <hr />
                                                    <b>{"lot amount : "}</b>
                                                    <span style={{ color: "#2dbc9b" }}>{e.lot_amount}</span>
                                                    <div id="low"><b>{"status : "}</b>
                                                        <span style={{
                                                            color: e.status === "success"
                                                                ? "#25cc00"
                                                                : e.status ===
                                                                    "failure"
                                                                    ? "red"
                                                                    : "#fff",
                                                        }}>{e.status}</span></div>
                                                </Card>
                                            </Grid>
                                        ))}
                                        {showViewMore ? (
                                            <Grid item lg={12} xs={12}>
                                                <div style={{ textAlign: "center", width: "100%" }}>
                                                    <CustomTextButton
                                                        color="primary"
                                                        variant="contained"
                                                        title="View More"
                                                        onClick={() => fetchMoreNfts()}
                                                        size="small"
                                                    />
                                                </div>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </div>
                            }
                            <p>Note: Pending results will be published tomorrow</p>
                        </>
                    ) : (
                        <EmptyBox> No data found </EmptyBox>
                    )}
                </Grid>
            </div>
        </>
    )
}

export default Results