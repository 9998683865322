import { post } from "services";
import { FETCH_CRYPTO_PRICE_LIST, FETCH_TODAYS_PRICE_HISTORY_OF_CRYPTO } from "graphql";
import { TOGGLE_LOADING, LIST_PRICE_HISTORY_OF_CRYPTO, RESET_PRICE_HISTORY_OF_CRYPTO } from "store/types";
import { LIST_CRYPTO_PRICE_LIST, RESET_CRYPTO_PRICE_LIST } from "store/types";


export const getTodaysCryptoPriceHistory = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_TODAYS_PRICE_HISTORY_OF_CRYPTO,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_crypto_price_history: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_PRICE_HISTORY_OF_CRYPTO,
                payload: {
                    priceHistory: data
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetTodaysCryptoPriceHistory = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_PRICE_HISTORY_OF_CRYPTO });
    };
};

export const getCryptoPriceList = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_CRYPTO_PRICE_LIST,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                get_last_crypto_price_list: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_CRYPTO_PRICE_LIST,
                payload:  data[0],
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetCryptoPriceList = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_CRYPTO_PRICE_LIST });
    };
};