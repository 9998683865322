import { post } from "services";
import {
    FETCH_STOCK_PRICE_HISTORY,
    FETCH_ALL_COMPANIES,
    GET_TOTAL_COINS,
    GET_PREDICTION_HISTORY,
    UPDATE_LOTS_AND_COINS,
    GET_SIGNAL_RESULT_HISTORY
} from "graphql";
import {
    TOGGLE_LOADING, 
    LIST_STOCK_PRICE_HISTORY, 
    LIST_COMPANIES_LIST,
    RESET_STOCK_PRICE_HISTORY,
    RESET_COMPANIES_LIST,
    RESET_TOTAL_COINS,
    SHOW_TOTAL_COINS,
    LIST_PREDICTION_HISTORY,
    RESET_PREDICTION_HISTORY,
    LIST_SIGNAL_RESULT_HISTORY,
    RESET_SIGNAL_RESULT_HISTORY
} from "store/types";
import { toast } from "react-toastify";
import { FETCH_LOT_AVAILABILITY_OF_COUNTRIES } from "graphql";
import { LIST_LOT_AVAILABILITY_OF_COUNTRIES } from "store/types";
import { RESET_LOT_AVAILABILITY_OF_COUNTRIES } from "store/types";
// import { UPDATE_PREDICTION_RESULTS } from "graphql";

export const listStockPriceHistory = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_STOCK_PRICE_HISTORY,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                get_stock_price_history: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_STOCK_PRICE_HISTORY,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAvailabilityOfCountries = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_LOT_AVAILABILITY_OF_COUNTRIES,
        });
        
        if (apiResponse.data.customStatus) {
            let {
                list_lot_availability_of_countries: { countries },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_LOT_AVAILABILITY_OF_COUNTRIES,
                payload: countries,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllCompanies = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_ALL_COMPANIES,
            variables: requestData,
        });
        
        if (apiResponse.data.customStatus) {
            let {
                list_all_companies: { all_companies },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_COMPANIES_LIST,
                payload: all_companies,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const getTotalCoins = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: GET_TOTAL_COINS,
        });

        if (apiResponse.data.customStatus) {
            let {
                get_total_coins: { data },
            } = apiResponse.data.data;

            dispatch({
                type: SHOW_TOTAL_COINS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateLotsAndCoins = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_LOTS_AND_COINS,
            variables: requestData
        });

        if (apiResponse.data.customStatus) {
            toast.success("You took the lots");
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const getPredictionHistory = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { pageSize, pageNumber, predictionHistory } = getState().user;

        let apiResponse = await post("", {
            query: GET_PREDICTION_HISTORY,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                get_prediction_history: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...predictionHistory, ...data];

            dispatch({
                type: LIST_PREDICTION_HISTORY,
                payload: {
                    predictionHistory: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

// export const updatePredictionResults = () => {
//     return async (dispatch) => {
//         dispatch({ type: TOGGLE_LOADING, payload: true });

//         let apiResponse = await post("", {
//             query: UPDATE_PREDICTION_RESULTS
//         });

//         if (apiResponse.data.customStatus) {
//             console.log("results updated");
//         }
//         dispatch({ type: TOGGLE_LOADING, payload: false });
//     };
// };

export const getSignalResultHistory = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { pageSize, pageNumber, resultHistory } = getState().user;

        let apiResponse = await post("", {
            query: GET_SIGNAL_RESULT_HISTORY,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                get_signal_result_history: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...resultHistory, ...data];

            dispatch({
                type: LIST_SIGNAL_RESULT_HISTORY,
                payload: {
                    resultHistory: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetSignalResultHistory = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_SIGNAL_RESULT_HISTORY });
    };
};

export const resetPredictionHistory = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_PREDICTION_HISTORY });
    };
};

export const resetCompaniesList = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_COMPANIES_LIST });
    };
};

export const resetCountriesAvailabilityList = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_LOT_AVAILABILITY_OF_COUNTRIES });
    };
};

export const resetStockPriceHistory = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_STOCK_PRICE_HISTORY });
    };
};

export const resetTotalCoins= () => {
    return async (dispatch) => {
        dispatch({ type: RESET_TOTAL_COINS });
    };
};