export * from "./isLogged";
export * from "./history";
export * from "./timezoneConverter";


export const currencyFormat = (number, prefix = "₹") => {
    // return new Intl.NumberFormat("en-IN", {
    //   style: "currency",
    //   currency: "INR",
    // }).format(number);
    if (typeof number === "undefined") {
        number = 0;
    }
    return (
        prefix +
        Number(number)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    );
};

export const dateConverter = (str) => {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("-");
}

export const getCurrentDate = () => {
    var today = new Date(); 
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd
    return today;
}

export const dateFromXDaysAgo = (x) => {
    var date = new Date();
    date.setDate(date.getDate()-x);
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    date = yyyy + '-' + mm + '-' + dd
    return date;
}