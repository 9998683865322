import React, { useState } from "react";
import { Box, Collapse, Grid } from "@material-ui/core";
import { ContainedButton } from "components/StyledComponents";
import "./styles.css";
import { EmptyBox } from "components/StyledComponents";

function ExpandableListView({
    data = [],
    pairs = [],
    iconImg = "",
    note,
    leftTitle = [],
    rightTitle = {},
    endButton,
    showViewMoreButton = false,
    viewMoreButtonClick,
}) {
    const [expandBtn, setExpandBtn] = useState(0);

    const handleExpandButton = (e) => {
        if (e === expandBtn) {
            setExpandBtn(0);
        } else {
            setExpandBtn(e);
        }
    };

    const colorIndicator = (element, value) => {
        if (element === value) {
            return { color: "#6bc654" };
        } else {
            return { color: "red" };
        }
    };

    return (
        <>
            <Box sx={{ width: "100%" }}>
                {data?.length ? (
                    <div className="listings">
                        <Grid container spacing={1}>
                            {data.map((e, i) => (
                                <Grid item lg={6} xs={12} key={i}>
                                    <div className="element">
                                        <div className="item" onClick={() => handleExpandButton(i + 1)}>
                                            {iconImg ?
                                            <div className="icon"><img src={iconImg.render ? iconImg.render(e) : iconImg} alt=""/></div> :
                                            <div className="icon">{Array.from(e[leftTitle[0]?.key])[0]}</div> 
                                            }
                                            {leftTitle?.length ? (
                                                <div className="title">
                                                    <div> {leftTitle[0]?.render ? leftTitle[0]?.render(e) : e[leftTitle[0]?.value]} </div>
                                                    {expandBtn !== i + 1 ? (
                                                        <div className="sub">
                                                            {leftTitle[1]?.render ? leftTitle[1]?.render(e) : e[leftTitle[1]?.value]}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                            <div className="value">
                                                {expandBtn !== i + 1 ? (
                                                    <>
                                                        {rightTitle?.main?.render ? rightTitle?.main?.render(e) : e[rightTitle?.main]}
                                                        <br />
                                                        <div>
                                                            {rightTitle?.sub?.render ? rightTitle?.sub?.render(e) : e[rightTitle?.sub]}
                                                        </div>
                                                    </>
                                                ) : null}
                                            </div>
                                            <div className="dropdown-btn" title="expand">
                                                <i
                                                    className={
                                                        expandBtn === i + 1
                                                            ? "fas fa-caret-up"
                                                            : "fas fa-caret-down"
                                                    }
                                                    onClick={() => handleExpandButton(i + 1)}
                                                />
                                            </div>
                                        </div>
                                        {expandBtn === i + 1 ? (
                                            <Collapse in={true}>
                                            <div className="expanded">
                                                {pairs.map((element, index) => (
                                                    <div className="key-value-pairs" key={index}>
                                                        <div className="key"> {element.key} </div>
                                                        {element.colorIndication ? (
                                                            <div
                                                                className="value"
                                                                style={colorIndicator(
                                                                    e[element.colorIndication.value],
                                                                    element.colorIndication.condition
                                                                )}
                                                            >
                                                                {e[element.value]}
                                                            </div>
                                                        ) : (
                                                            <div className="value">
                                                                {element.render
                                                                    ? element.render(e)
                                                                    : e[element.value] ?? ""}{" "}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}

                                                {note ? note(e) : null}

                                                {endButton ? (
                                                    <button onClick={() => endButton?.action(e)}>{endButton?.title}</button>
                                                ) : null}
                                            </div>
                                            </Collapse>
                                        ) : null}
                                    </div>
                                </Grid>
                            ))}
                            {showViewMoreButton ? (
                                <div className="view-more">
                                    <ContainedButton
                                        style={{ padding: "6px 10px", fontSize: "small" }}
                                        onClick={viewMoreButtonClick}
                                    >
                                        View more
                                    </ContainedButton>
                                </div>
                            ) : null}
                        </Grid>
                    </div>
                ) : (
                    // <div className="empty-msg">
                    <EmptyBox>No data found</EmptyBox>
                    // </div>
                )}
            </Box>
        </>
    );
}

export default ExpandableListView;
