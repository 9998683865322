export const FETCH_ALL_COMPANIES = `query ($countryExchange: String!){
  list_all_companies(country_exchange: $countryExchange){
    message
    all_companies{
      scrip_name
      company_name
      cmp
      target
      signal
      date
      lots_available
      createdAt
      updatedAt
    }
  }
}`;

export const FETCH_LOT_AVAILABILITY_OF_COUNTRIES = `query{
  list_lot_availability_of_countries{
    message
    countries{
      code
      country
      market
      availability
    }
  }
}`;

export const FETCH_STOCK_PRICE_HISTORY = `query ($startDate: String!, $endDate: String!, $symbol: String!){
  get_stock_price_history(start_date: $startDate,end_date: $endDate,symbol: $symbol){
    message
    currency
    data{
      date
      open
      high
      low
      close
      volume
      adjclose
    }
  }
}`;

export const GET_TOTAL_COINS = `query{
    get_total_coins{
      message
      data{
        _id
        total_coins
      }
    }
  }`;

export const UPDATE_LOTS_AND_COINS = `mutation ($lotAmount: String!, $data: update_available_lots_and_coins_data!){
  update_available_lots_and_coins(lot_amount: $lotAmount, data: $data){
    message
  }
}`;

export const GET_PREDICTION_HISTORY = `query ($type: String, $pageSize: Int, $pageNumber: Int) {
  get_prediction_history (type: $type, page_size: $pageSize, page_number: $pageNumber) {
    message
    data{
      _id
      user_id
      date
      country
      country_code
      market
      company_name
      cmp
      target
      signal
      lot_amount
      prediction
      status
      gain
      loss
      days_high
      days_low
    }
    hasMore
  }
}`;

// export const UPDATE_PREDICTION_RESULTS = `mutation{
//   update_prediction_results{
//     message
//   }
// }`;

export const GET_SIGNAL_RESULT_HISTORY = `query ($type: String, $pageSize: Int, $pageNumber: Int) {
  get_signal_result_history (type: $type, page_size: $pageSize, page_number: $pageNumber) {
    message
    data{
      _id
      date
      country
      country_code
      market
      company_name
      scrip_name
      cmp
      target
      signal
      status
      date_of_result
      days_high
      days_low
      createdAt
      updatedAt
    }
    hasMore
  }
}`;