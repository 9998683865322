import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Grid, Modal } from "@material-ui/core";
import { Title } from "components/StyledComponents";
import { resetTodaysCryptoPriceHistory, getTodaysCryptoPriceHistory } from "store/actions";
import { resetCryptoPriceList } from "store/actions";
import { getCryptoPriceList } from "store/actions";
import moment from "moment";
import { Icon } from "@iconify/react";
import { Button, Stack, Typography } from "@mui/material";
import styled from "styled-components";
import { OutlinedButton } from "components/StyledComponents";
import { ContainedButton } from "components/StyledComponents";
import { toggleModal } from "store/actions";
// import Countdown from "react-countdown";

const ModalBox = styled.div`
    width: 380px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #101427;
    color: #cccccc;
    border-radius: 20px;
    padding: 30px 20px;
    text-align: center;
    box-shadow: 0 3px 70px #000000;
    h3 {
        margin-bottom: 30px;
    }

    @media screen and (max-width: 500px) {
        width: 300px;
    }
`;

function CryptoSection() {
    const dispatch = useDispatch();
    const [closeModal, setCloseModal] = useState(false);
    // const [timer, setTimer] = useState(0);

    const { priceHistory, priceList } = useSelector(({ crypto: { priceHistory, priceList } }) => ({
        priceHistory,
        priceList
    }));

    useEffect(() => {
        dispatch(resetTodaysCryptoPriceHistory());
        dispatch(resetCryptoPriceList());
        dispatch(getTodaysCryptoPriceHistory());
        dispatch(getCryptoPriceList());

        // timeCalc();
    }, []);

    // const timeCalc = () => {
    //     let currentTime = moment();
    //     let slotTime = priceList?.createdAt;
    //     var mins = moment.utc(moment(currentTime, "HH:mm:ss").diff(moment(slotTime, "HH:mm:ss"))).format("mm");
    //     var m = 15 - mins;
    //     var millisecs = m * 100000;
    //     setTimer(parseFloat(millisecs));
    // }
    return (
        <>
            <Grid container justify="center" spacing={3}>
                <Grid item lg={12} xs={10}>
                    <Title>
                        <h4>{moment(priceList?.createdAt).format("DD-MM-YYYY hh:mm A z(Z)")}</h4>
                    </Title>
                </Grid>

                <Grid item lg={12} xs={12}>
                    <Grid container spacing={3} justify="center">
                        {priceList?.prices?.map((e, i) => (
                            <Grid item lg={3} md={6} xs={12} key={i}>
                                <Card style={{ padding: 20, color: "#ccc" }}>
                                    <Stack spacing={2}>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <Icon width={60} icon={`cryptocurrency-color:${e?.crypto_symbol.toLowerCase()}`} />
                                            <Stack sx={{width:"100%"}} direction="row" justifyContent="space-between">
                                                <Stack>
                                                <b>{e.crypto_symbol}</b>
                                                <b>${e.crypto_price}</b>
                                                <small>{moment(priceList?.createdAt).format("hh:mm A")}</small>
                                                </Stack>
                                                {/* <Stack>
                                                    <b><Countdown date={Date.now() + timer} /></b>
                                                </Stack> */}
                                                {/* <Stack>
                                                <Icon icon="material-symbols:arrow-right-alt" width="20" />
                                                </Stack>
                                                <Stack>
                                                <b>{e.crypto_symbol}</b>
                                                <b>${e.crypto_price}</b>
                                                <small>{moment(priceList?.createdAt).format("hh:mm A")}</small>
                                                </Stack> */}
                                            </Stack>
                                        </Stack>
                                        <Typography variant="body">
                                            {`Price of ${e.crypto_symbol} is $${e.crypto_price} at ${moment(priceList?.createdAt).format("hh:mm A")}.`}
                                        </Typography>
                                        <Typography variant="body1" sx={{minHeight: "40px"}}>
                                            {`Do you think ${e.crypto_symbol} to reach`} <b> {`$${e.crypto_price + 1.5}`} </b> {`at ${moment(priceList?.createdAt).add(15, "minutes").format("hh:mm A")}?`}
                                        </Typography>
                                        <Stack direction="row" spacing={2}>
                                            <Button fullWidth variant="outlined" sx={{color: "yellow", borderColor:"yellow"}} onClick={() => setCloseModal(true)}>
                                                No 2
                                            </Button>
                                            <Button fullWidth variant="contained" sx={{backgroundColor: "yellow", color:"black"}} onClick={() => setCloseModal(true)}>
                                                Yes 15
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            <Modal open={closeModal} onClose={() => setCloseModal(false)}>
                <ModalBox>
                    <h3>Please sign in first!</h3>
                    <OutlinedButton onClick={() => setCloseModal(false)}>
                        Cancel
                    </OutlinedButton>
                    &nbsp;&nbsp;&nbsp;
                    <ContainedButton
                        onClick={() => {
                            setCloseModal(false);
                            dispatch(toggleModal(true));
                        }}
                    >
                        Ok
                    </ContainedButton>
                </ModalBox>
            </Modal>
        </>
    );
}

export default CryptoSection;
