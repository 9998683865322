export const countries = [
    { code: "usa_dj", country: "USA", market: "Dow Jones", icon: "", endTime: "Fri Sep 23 2022 18:30:00", timeZone: "America/New_York" },
    { code: "nifty_50", country: "India", market: "Nifty", icon: "" },
    // { code: "bse_30", country: "India", market: "BSE", icon: "" },
    // { code: "canada", country: "Canada", market: "TSE", icon: "" },
    // { code: "germany", country: "Germany", market: "XETRA", icon: "" },
    { code: "uk", country: "UK", market: "LSE", icon: "", endTime: "Fri Sep 23 2022 13:00:00", timeZone: "Europe/London" },
    // { code: "brazil", country: "Brazil", market: "b3", icon: "" },
    // { code: "south_africa", country: "South Africa", market: "JSE", icon: "" },
    // { code: "france", country: "France", market: "Euronext", icon: "" },
    // { code: "singapore", country: "Singapore", market: "SGX", icon: "" },
    { code: "hongkong", country: "Hong Kong", market: "HKSE", icon: "" },
    // { code: "malaysia", country: "Malaysia", market: "BURSA", icon: "" },
    // { code: "indonesia", country: "Indonesia", market: "IDX", icon: "" },
    // { code: "mexico", country: "Mexico", market: "BMV", icon: "" },
    { code: "japan", country: "Japan", market: "TYO", icon: "" },
    // { code: "dubai", country: "Dubai", market: "DFMGI", icon: "" },
]